import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    dataUtama: {
      id: '',
      sapk: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },
    rwanak: {
      id: '',
      sapk: {
        id: null
      },
      NIK: null,
      nama: '',
      tempatLahir: '',
      tglLahir: '',
      aktaMeninggal: null,
      tglMeninggal: null,
      jenisKelamin: '',
      ortu: {
        id: null,
        nama: null,
        jenisAnak: ''
      },
      USER_ID: '',
      NCSISTIME: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },
    editModal(state, value) {
      state.rwanak = value
    },
    editDatautama(state, value) {
      state.dataUtama = value
    }
  }
})
