<template>
  <div>
    <v-card flat>
      <v-app-bar color="primary" short dense dark flat>
        <v-toolbar-title>Riwayat Anak</v-toolbar-title>
        <v-spacer></v-spacer>

        <div
          class="mr-3 mt-1"
        >
          <rwanakModalAdd />
        </div>

        <div style="max-width: 300px">
          <v-tabs v-model="tab" right dark background-color="primary">
            <v-tab href="#tab-1" @click="getSIMASN()">
              <h5>IDASN</h5>
            </v-tab>

            <v-tab href="#tab-2" @click="getSAPK()">
              <h5>SIASN</h5>
            </v-tab>
          </v-tabs>
        </div>
      </v-app-bar>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-1" class="pa-5">
          <rwanakSIMASN />
        </v-tab-item>
        <v-tab-item value="tab-2" class="pa-5">
          <rwanakSAPK />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getData from "@/store/asn/rwanak/tabs";

import rwanakSIMASN from "@/components/asn/profil/rwanak/rwanak";
import rwanakSAPK from "@/components/asn/profil/rwanak/SAPK_rwanak_Modal";
import rwanakModalAdd from "@/components/asn/profil/rwanak/ModalAdd";

export default {
  name: "DataUtama",

  components: {
    rwanakSIMASN,
    rwanakSAPK,
    rwanakModalAdd,
  },

  computed: {
    dataSAPK: {
      get() {
        return getData.state.tabSAPK;
      },

      set(value) {
        getData.commit("getSAPK", value);
      },
    },
  },

  data: () => ({
    session: '',
    tab: null,
  }),

  async mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.get_nip = this.$route.params.id;
  },

  methods: {
    getSAPK() {
      getData.commit("getSAPK", true);
      getData.commit("getSIMASN", false);
    },
    getSIMASN() {
      getData.commit("getSIMASN", true);
      getData.commit("getSAPK", false);
    },
  },
};
</script>
