import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ModalToSIMASN: false,
        
        dataUtama: {
            id: '',
            SAPK: {
                id: ''
            },
            nipBaru: '',
            nipLama: ''
        },

        rwanak: {
            id: '',
            sapk: {
                id: null
            },
            NIK: null,
            nama: '',
            tempatLahir: '',
            tglLahir: '',
            aktaMeninggal: null,
            tglMeninggal: null,
            jenisKelamin: '',
            ortu: {
                id: null,
                nama: null,
                jenisAnak: ''
            },
            USER_ID: '',
            NCSISTIME: ''
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.ModalToSIMASN = value
        },
        tosimasnModal(state, value) {
            state.rwanak = value
        },
        Datautama(state, value) {
            state.dataUtama = value
        }
    }
})
