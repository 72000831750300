<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="subtitle-1">Edit Data Anak</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <!-- Nama Anak -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Anak</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="editedItem.nama"
                  hint="Nama Anak"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="editedItem.NIK"
                  hint="NIK Anak"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="editedItem.jenisKelamin"
                  :items="jenkel"
                  hint="Jenis Kelamin"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Tempat / Tanggal Lahir -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tempat / Tanggal Lahir</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="editedItem.tempatLahir"
                  hint="Tempat Lahir"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglLahirPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.tglLahir"
                      hint="Tanggal Lahir"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.tglLahir"
                    @input="tglLahirPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Data Kematian -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Data Kematian</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="editedItem.aktaMeninggal"
                  hint="Akta Meninggal"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglMeninggalPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.tglMeninggal"
                      hint="Tanggal Meninggal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.tglMeninggal"
                    @input="tglMeninggalPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Nama Orang Tua -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Orang Tua</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-select
                  v-model="editedItem.ortu.id"
                  :items="refOrtu"
                  item-value="id"
                  item-text="nama"
                  hint="Nama Orang Tua"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="editedItem.ortu.jenisAnak"
                  :items="refAnak"
                  item-value="id"
                  item-text="nama"
                  hint="Status Anak"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import apiURL from '@/helper/getURL.js'
import Cookie from '@/helper/cookie.js'
import Auth from '@/helper/auth.js'

import refreshView from '@/store/asn/rwanak/viewRwanak'
import modal_editState from '@/store/asn/rwanak/modal_edit'

export default {
  name: 'rwanakModalEdit',

  computed: {
    ModalEdit: {
      get() {
        return modal_editState.state.ModalEdit
      },

      set(value) {
        modal_editState.commit('toggleModal', value)
      },
    },

    editedItem: {
      get() {
        return modal_editState.state.rwanak
      },

      set(value) {
        console.log(value)
      },
    },
  },

  data: () => ({
    token: Cookie.get('token'),
    btnLoading: true,
    rwanak: {},

    refAnak: [
      {
        id: 'kandung',
        nama: 'Kandung',
      },
      {
        id: 'angkat',
        nama: 'Angkat',
      },
      {
        id: 'tiri',
        nama: 'Tiri',
      },
    ],
    jenkel: ['Pria', 'Wanita'],
    refOrtu: [],

    // Date Picker
    tglLahirPicker: false,
    tglMeninggalPicker: false,
  }),

  async mounted() {
    this.get_nip = this.$route.params.id
    this.getData()
  },

  methods: {
    reset() {
      this.rwanak = {
        id: '',
        sapk: {
          id: null,
        },
        NIK: null,
        nama: '',
        tempatLahir: '',
        tglLahir: '',
        aktaMeninggal: null,
        tglMeninggal: null,
        jenisKelamin: '',
        ortu: {
          id: null,
          nama: null,
          jenisAnak: '',
        },
        USER_ID: '',
        NCSISTIME: '',
      }
    },

    async getData() {
      this.get_nip = this.$route.params.id
      const token = await Cookie.get('token')
      const url = apiURL.baseURL + 'anak/nip/' + this.get_nip + '/ortu'

      this.http
        .get(
          url,
          { headers: apiURL.Config(token).headers },
          apiURL.Config(token).data
        )
        .then((response) => {
          this.refOrtu = response.data.mapData.DataOrtu
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status)
          } else {
            console.log('Error', error.message)
          }
        })
    },

    async edit() {
      this.btnLoading = false
      let token = await Cookie.get('token')
      let data = {
        id: this.editedItem.id,
        NIK: this.editedItem.NIK,
        nama: this.editedItem.nama,
        tempatLahir: this.editedItem.tempatLahir,
        tglLahir: this.editedItem.tglLahir,
        aktaMeninggal: this.editedItem.aktaMeninggal,
        tglMeninggal: this.editedItem.tglMeninggal,
        jenisKelamin: this.editedItem.jenisKelamin,
        ortu: {
          id: this.editedItem.ortu.id,
          jenisAnak: this.editedItem.ortu.jenisAnak,
        },
      }
      const url = apiURL.baseURL + 'anak/nip/' + this.get_nip
      this.http
        .put(url, data, { headers: apiURL.Update(token).headers })
        .then((response) => {
          this.btnLoading = true
          if (response.data.success) {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', response.data.message)
            refreshView.commit('berhasilAlert', true)
            refreshView.commit('gagalAlert', false)
            refreshView.commit('success', response.data.success)
          } else {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', response.data.success)
          }
          this.closeModal()
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token)
          } else if (error.response) {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', error.response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', error.response.data.success)
            console.log(error.response.status)
            this.btnLoading = true
          } else {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', error.response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', error.response.data.success)
            console.log(error.response.status)
            console.log('Error', error.message)
            this.btnLoading = true
          }
        })
    },

    async closeModal() {
      await this.reset()
      await modal_editState.commit('editModal', Object.assign({}, this.rwanak))
      await refreshView.commit('refreshData', true)
      this.ModalEdit = false
    },
  },
}
</script>
