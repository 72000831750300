<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rwanak_SAPK"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      :loading="loading"
      hide-default-footer
    >
      <template v-slot:top>
        <rwanakModalToSIMASN />
      </template>
      <template v-slot:[`item.kolom1`]="{ item }">
        {{ item.nama }} <br />
        {{ item.tempatLahir }}, {{ item.tglLahir }}
      </template>
      <template v-slot:[`item.jenisKelamin`]="{ item }">
        <span v-if="item.jenisKelamin == 'M'"> Laki-laki </span>
        <span v-else> Perempuan </span>
      </template>
      <template v-slot:[`item.jenisAnak`]="{ item }">
        <span v-if="item.jenisAnak == 1"> Kandung </span>
        <span v-else> Angkat </span>
      </template>

      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="toSIMASN(item)" v-bind="attrs" v-on="on">
              <v-icon color="primary"> mdi-database-arrow-left </v-icon>
            </v-btn>
          </template>
          <span>Salin Data</span>
        </v-tooltip>
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
import apiURL from "@/helper/getURL.js";
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import modal_tosimasnState from "@/store/asn/rwanak/modal_to_simasn";
import refreshView from "@/store/asn/rwanak/tabs";

import rwanakModalToSIMASN from "@/components/asn/profil/rwanak/ModalToSIMASN";

export default {
  components: {
    rwanakModalToSIMASN,
  },

  computed: {
    tabSAPK: {
      get() {
        return refreshView.state.tabSAPK;
      },
    },
  },

  watch: {
    tabSAPK(newVal) {
      if (newVal) {
        this.getDataSAPK();
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    Modal_rwanak_SAPK: false,
    loading: false,
    rwanak_SAPK: [],
    get_nip: "",

    headers: [
      {
        text: "Nama Anak / Tempat Tanggal lahir",
        align: "start",
        sortable: false,
        value: "kolom1",
      },
      { text: "Jenis Kelamin", value: "jenisKelamin" },
      { text: "Keterangan", value: "jenisAnak" },
      // { text: "Actions", value: "actions", sortable: false, align: "center" },
    ],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
    if (this.tabSAPK) {
      this.getDataSAPK();
    }
  },

  methods: {
    getDataSAPK() {
      this.loading = true;
      const token = Cookie.get("token");
      const url = apiURL.baseURL + "anak/nip/" + this.get_nip + "/bkn";

      this.http
        .get(
          url,
          { headers: apiURL.Config(token).headers },
          apiURL.Config(token).data
        )
        .then((response) => {
          this.rwanak_SAPK =
            response.data.mapData.DataKeluarga.kelPasangan.kelAnak;
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    toSIMASN(item) {
      modal_tosimasnState.commit("toggleModal", true);
      modal_tosimasnState.commit("tosimasnModal", item);
      modal_tosimasnState.commit("Datautama", this.dataUtama);
    },
  },
};
</script>
